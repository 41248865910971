exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bilan-neuropsychologique-tsx": () => import("./../../../src/pages/bilan-neuropsychologique.tsx" /* webpackChunkName: "component---src-pages-bilan-neuropsychologique-tsx" */),
  "component---src-pages-cabinet-hebella-lyon-tsx": () => import("./../../../src/pages/cabinet-hebella-lyon.tsx" /* webpackChunkName: "component---src-pages-cabinet-hebella-lyon-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-maison-sante-brignais-tsx": () => import("./../../../src/pages/maison-sante-brignais.tsx" /* webpackChunkName: "component---src-pages-maison-sante-brignais-tsx" */),
  "component---src-pages-prendre-rendez-vous-tsx": () => import("./../../../src/pages/prendre-rendez-vous.tsx" /* webpackChunkName: "component---src-pages-prendre-rendez-vous-tsx" */),
  "component---src-pages-presentation-tsx": () => import("./../../../src/pages/presentation.tsx" /* webpackChunkName: "component---src-pages-presentation-tsx" */),
  "component---src-pages-remediation-cognitive-tsx": () => import("./../../../src/pages/remediation-cognitive.tsx" /* webpackChunkName: "component---src-pages-remediation-cognitive-tsx" */),
  "component---src-pages-tarifs-tsx": () => import("./../../../src/pages/tarifs.tsx" /* webpackChunkName: "component---src-pages-tarifs-tsx" */)
}

